.navbar {
  /* background-color: #060b26; */
  /* background: hsla(210, 20%, 90%, 1); */
  box-shadow: none;
  padding-bottom: 1em;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: flex-start;
  height: 100%;
  left: auto;
  right: 0;
}

.menu-bars {
  font-size: 2rem;
  background: none;
}

.nav-menu {
  /* background-color: #060b26; */
  background: hsla(210, 20%, 90%, 1);
  width: 215px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: auto;
  right: -100%;
  transition: 850ms;
  overflow: auto;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  right: 0;
  /* Déplacez le menu vers la droite lorsqu'il est actif */
  left: auto;
}

.nav-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  transition-duration: 0.4s;
}

.nav-text:hover {
  background-color: #d0d6db;
}

.nav-text a {
  text-decoration: none;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  border-radius: 4px;
  color: #000;
}

.nav-text a:hover {
  /* background-color: #1a83ff; */

}

.nav-menu-items {
  width: 100%;
}

nav button {
  width: 100%;
  text-align: left;
}

.navbar-toggle {


  box-shadow: none;
  padding-bottom: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

span {
  margin-left: 16px;
  width: 100%;

}

.linkBtn {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

.linkBtn :focus {
  background-color: #d0d6db;

}

.linkBtn :hover {
  background-color: #d0d6db;

}