@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  text-align: center;

}

body {
  background: hsla(210, 20%, 90%, 1);

}

.back {
  background: hsla(210, 20%, 90%, 1);

}


.iconType {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-size: 35px;
}

hr {
  width: 70%;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.cards {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.card {
  background: #fff;
  width: 15em;
  /* height: 22em; */
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.cardDetail {
  width: 250px;

}

.closeCard {
  font-size: 1.7em;
  /* color: black; */
  /* border: solid 2px #08249a; */
  border-radius: 4px;
  background-color: #fff;
}

.footerCard {
  background: hsla(210, 20%, 90%, 1);
  width: 100%;
  height: 100%;
  cursor: default !important;

}

.card:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  width: 100%;
  object-fit: cover;
}

.inputCard {
  width: 80%;
  margin-left: -35px;
  background: transparent;
  border: solid 2px black;
  border-radius: 5px;
}

.inputLog {
  /* width: 100%; */
  /* margin-left: -35px; */
  background: transparent;
  /* border: solid 2px black; */
  border-radius: 5px;
}

.selectCard {
  text-align: left;
  width: 80%;
  margin-left: -35px;
  background: transparent;
  border: solid 2px black;
  border-radius: 5px;
  padding: 2px;
  text-decoration: none;
  font-size: 18px;
}

.modalCustom {
  max-width: 100%;
  background: transparent !important;
  min-height: 0 !important;
  width: auto !important;
  height: auto !important;
  display: inline-block;
  box-shadow: none !important;
}

.modalZoom {
  max-width: 100%;
  max-height: 100%;
  background: #fff;
  width: auto !important;
  height: auto !important;
  display: inline-block;
}

.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-align: center;
}

.card ref {
  color: rgb(14, 1, 1);
  margin-top: -0.2em;
  line-height: 1.2;
  font-size: 1.1em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-align: center;

}

.card p {
  color: #777;
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: 0;
}

.card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1em 0 1em 0;
  text-transform: uppercase;
  text-align: center;
}

.catalogue {
  background: hsla(210, 20%, 90%, 1);
  padding: 0em 0;
  margin: auto;
  width: 100%;
  line-height: 1.6;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.detailProd {
  padding: 0em 0;
  line-height: 1.6;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.loginForm {
  background: hsla(210, 20%, 90%, 1);
  padding: 0em 0;
  width: 100%;
  height: 100%;
  line-height: 1.6;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.login-container {
  margin-top: 5%;
  margin-bottom: 5%;
  display: inline-block;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  width: 55%;
}

.login-form-1 {
  padding: 5%;
  /* box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19); */
}

.login-form-1 input {
  background: none;
}

.login-form-1 h3 {
  text-align: center;
  color: #333;
}

.login-form-2 {
  padding: 5%;
  background: #0062cc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form-2 h3 {
  text-align: center;
  color: #fff;
}

.login-container form {
  padding: 2%;
}

.btnSubmit {
  /* width: 50%; */
  font-weight: 100;
  border-radius: 0.2rem;
  padding: 0.7%;
  border: none;
  cursor: pointer;
}

.login-form-1 .btnSubmit {
  font-weight: 300;
  color: #fff;
  background-color: #0062cc;
}

.login-form-2 .btnSubmit {
  font-weight: 600;
  color: #0062cc;
  background-color: #fff;
}

.luxurious-modal .ant-modal-close {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  right: 25px !important;
  /* Décaler le bouton de fermeture de 25px vers la gauche */
}

.luxurious-modal .ant-modal-close .ant-modal-close-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  /* Assurez-vous qu'il n'y a pas de padding pour centrer correctement */
  margin: 0;
  /* Assurez-vous qu'il n'y a pas de marge */
}
.navbar {
  /* background-color: #060b26; */
  /* background: hsla(210, 20%, 90%, 1); */
  box-shadow: none;
  padding-bottom: 1em;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100%;
  left: auto;
  right: 0;
}

.menu-bars {
  font-size: 2rem;
  background: none;
}

.nav-menu {
  /* background-color: #060b26; */
  background: hsla(210, 20%, 90%, 1);
  width: 215px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: auto;
  right: -100%;
  transition: 850ms;
  overflow: auto;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  right: 0;
  /* Déplacez le menu vers la droite lorsqu'il est actif */
  left: auto;
}

.nav-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  transition-duration: 0.4s;
}

.nav-text:hover {
  background-color: #d0d6db;
}

.nav-text a {
  text-decoration: none;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0 16px;
  border-radius: 4px;
  color: #000;
}

.nav-text a:hover {
  /* background-color: #1a83ff; */

}

.nav-menu-items {
  width: 100%;
}

nav button {
  width: 100%;
  text-align: left;
}

.navbar-toggle {


  box-shadow: none;
  padding-bottom: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;

}

span {
  margin-left: 16px;
  width: 100%;

}

.linkBtn {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

.linkBtn :focus {
  background-color: #d0d6db;

}

.linkBtn :hover {
  background-color: #d0d6db;

}
